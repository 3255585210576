import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { GameState } from './gameStateStore';

type SoundType = {
  audio: HTMLAudioElement;
  volume: number;
};

const soundData: { [key: string]: SoundType } = {
  buttonClick: {
    audio: new Audio('/audio/sound_fx/button.wav'),
    volume: 0.4,
  },
  boom: {
    audio: new Audio('/audio/sound_fx/boom.wav'),
    volume: 0.4,
  },
  pop: {
    audio: new Audio('/audio/sound_fx/pop.wav'),
    volume: 0.4,
  },
  pumpkinStrike: {
    audio: new Audio('/audio/sound_fx/pumpkin_strike.wav'),
    volume: 0.6,
  },
  splat: {
    audio: new Audio('/audio/sound_fx/splat.wav'),
    volume: 0.6,
  },
};

export enum Sounds {
  buttonClick = 'buttonClick',
  boom = 'boom',
  pop = 'pop',
  pumpkinStrike = 'pumpkinStrike',
  splat = 'splat',
}

export enum Music {
  inGame = 'in-game-music',
  intro = 'intro-music',
}

const enemySounds: { [key: number]: SoundType } = {
  1: {
    audio: new Audio('/audio/sound_fx/enemy_fx_1.wav'),
    volume: 0.1,
  },
  2: {
    audio: new Audio('/audio/sound_fx/enemy_fx_2.mp3'),
    volume: 0.4,
  },
  3: {
    audio: new Audio('/audio/sound_fx/enemy_fx_3.wav'),
    volume: 0.4,
  },
  4: {
    audio: new Audio('/audio/sound_fx/enemy_fx_4.mp3'),
    volume: 0.4,
  },
  5: {
    audio: new Audio('/audio/sound_fx/enemy_fx_5.wav'),
    volume: 0.4,
  },
  6: {
    audio: new Audio('/audio/sound_fx/enemy_fx_6.wav'),
    volume: 0.2,
  },
  7: {
    audio: new Audio('/audio/sound_fx/enemy_fx_7.wav'),
    volume: 0.3,
  },
  8: {
    audio: new Audio('/audio/sound_fx/enemy_fx_8.wav'),
    volume: 0.4,
  },
  9: {
    audio: new Audio('/audio/sound_fx/enemy_fx_9.wav'),
    volume: 0.4,
  },
};

export interface AudioState {
  soundsOn: boolean;
  musicOn: boolean;
  toggleSounds(): void;
  playMusic(music: Music): void;
  turnMusicOff(): void;
  turnMusicOn(gameState: GameState): void;
  handleSpawnSound(): void;
  playSound(sound: Sounds, fouceSounce?: boolean): void;
}

const playSound = (sound: SoundType): void => {
  sound.audio.pause();
  sound.audio.currentTime = 0;
  sound.audio.volume = sound.volume;
  sound.audio.play();
};

const useAudioStore = create<AudioState>()(
  subscribeWithSelector((set, get) => ({
    soundsOn: true,
    musicOn: false,
    playMusic(sound: Music) {
      set((state) => {
        if (state.musicOn) {
          // first pause all elements
          const audioElements = document.getElementsByTagName('audio');

          for (let i = 0; i < audioElements.length; i++) {
            audioElements[i].pause();
          }

          // if toggle on
          const musicElement = document.getElementById(
            sound
          ) as HTMLAudioElement;

          musicElement.play();
          musicElement.volume = 0.1;
        }
        return state;
      });
    },
    turnMusicOff() {
      set((state) => {
        const audioElements = document.getElementsByTagName('audio');
        for (let i = 0; i < audioElements.length; i++) {
          audioElements[i].pause();
        }
        return { musicOn: false };
      });
    },
    turnMusicOn(gameState: GameState) {
      set((state) => {
        state.musicOn = true;
        if (gameState === GameState.intro) {
          console.log('turn into music on!');
          state.playMusic(Music.intro);
        } else if (gameState === GameState.inGame) {
          console.log('turn game music on!');
          state.playMusic(Music.inGame);
        }

        return { musicOn: true };
      });
    },
    toggleSounds() {
      set((state) => {
        return { soundsOn: !state.soundsOn };
      });
    },
    handleSpawnSound() {
      const { soundsOn } = get();
      if (soundsOn) {
        const min = 1;
        const max = 9;

        const randEnemySpawn =
          Math.floor(Math.random() * (max - min + 1)) + min;
        try {
          playSound(enemySounds[randEnemySpawn]);
        } catch (e) {
          console.log('err playing: ', enemySounds[randEnemySpawn]);
        }
      }
    },
    playSound(sound: Sounds) {
      const { soundsOn } = get();
      try {
        if (soundsOn) playSound(soundData[sound]);
      } catch (e) {
        console.log('err playing: ', sound);
      }
    },
  }))
);

export default useAudioStore;
