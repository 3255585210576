import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

export interface GameStateStore {
  gameState: GameState;
  setGameState(gameState: GameState): void;
  playerHealth: number;
  setPlayerHealth(newHealth: number): void;
  defeatedEnemies: Map<string, number>;
  score: number;
  addScore(defeatedEnemy: string): void;
}

export enum GameState {
  landingPage,
  intro,
  tutorial,
  inGame,
  gameOver,
}

const defeatedEnemies = new Map<string, number>();

const useGameStateStore = create<GameStateStore>()(
  subscribeWithSelector((set, get) => ({
    gameState: GameState.landingPage,
    setGameState(gameState: GameState) {
      console.log('setting game state:', gameState);

      // reset values
      if (gameState === GameState.inGame) {
        set({
          playerHealth: 5,
          defeatedEnemies: new Map<string, number>(),
          score: 0,
        });
      }

      set({ gameState: gameState });
    },
    playerHealth: 5,
    setPlayerHealth(newHealth: number) {
      set({ playerHealth: newHealth });
      // game over
      if (newHealth < 1) {
        set({
          gameState: GameState.gameOver,
        });
      }
    },
    defeatedEnemies: new Map<string, number>(),
    score: 0,
    addScore(defeatedEnemy: string) {
      if (defeatedEnemies.has(defeatedEnemy)) return;
      defeatedEnemies.set(defeatedEnemy, 1);
      let { score } = get();
      set({ score: score + 1 });
    },
  }))
);

export default useGameStateStore;
