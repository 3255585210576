import { useEffect, useRef, useState } from 'react';
import {
  RigidBody,
  RapierRigidBody,
  CollisionEnterPayload,
  interactionGroups,
} from '@react-three/rapier';
import { useGLTF } from '@react-three/drei';
import { Vector3 } from 'three';
import Smoke from './Explosion/Smoke'; // Import Explosion component
import Flash from './Explosion/Flash';
import useAudioStore, { Sounds } from '../../../stores/audioStore';

type ProjectileProps = {
  position: [number, number, number];
  direction: Vector3;
  force: number;
  name: string;
  onRemove: (name: string) => void;
};

export default function Projectile({
  position,
  direction,
  force,
  name,
  onRemove,
}: ProjectileProps) {
  const ref = useRef<RapierRigidBody>(null);
  const { scene: pumpkinScene } = useGLTF('models/pumpkin.glb');
  const [smoking, setSmoking] = useState(false);
  const [exploding, setExploding] = useState(false);
  const [showPumpkin, setShowPumpkin] = useState(true);
  const [explosionPosition, setExplosionPosition] = useState<Vector3 | null>(
    null
  );
  const { playSound } = useAudioStore();
  // Apply impulse on mount
  useEffect(() => {
    setTimeout(() => {
      if (ref.current) {
        console.log(force, direction);
        ref.current.applyImpulse(
          {
            x: direction.x * force,
            y: direction.y * force,
            z: direction.z * force,
          },
          true
        );
      }
    }, 50);
  }, [direction, force]);

  const handleCollision = (e: CollisionEnterPayload) => {
    if (e.other.rigidBodyObject?.name.startsWith('enemy')) {
      playSound(Sounds.pumpkinStrike);
      setExplosionPosition(
        new Vector3(
          e.target.rigidBodyObject?.position.x ?? 0,
          e.target.rigidBodyObject?.position.y ?? 0,
          e.target.rigidBodyObject?.position.z ?? 0
        )
      );
      setSmoking(true);
      setExploding(true);

      setTimeout(() => {
        setShowPumpkin(false);
      }, 50);

      setTimeout(() => {
        onRemove(name); // Remove projectile after explosion
      }, 5000); // Delay to let explosion play
      return;
    }

    if (e.other.rigidBodyObject?.name.startsWith('ground')) {
      // TODO look at limiting this to just one or two sounds. Bounces get a little weird
      playSound(Sounds.splat);
      const numColliders = ref.current?.numColliders() ?? 0;

      // set collision group to ignore projectiles
      for (let i = 0; i < numColliders; i++) {
        ref.current
          ?.collider(i)
          .setCollisionGroups(interactionGroups([2], [0]));
      }
      return;
    }
    if (e.other.rigidBodyObject?.name.startsWith('grave_mound')) {
      playSound(Sounds.splat);
      e.target.rigidBody?.setEnabled(false);
      return;
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      onRemove(name);
    }, 5000); // Remove after 5 seconds

    return () => clearTimeout(timeout);
  }, [name, onRemove]);

  return (
    <group position={position} name={name}>
      {showPumpkin && (
        <RigidBody
          collisionGroups={interactionGroups([0], [0])}
          ref={ref}
          name={name}
          friction={3}
          colliders='trimesh'
          scale={[2, 2, 2]}
          onCollisionEnter={handleCollision}
        >
          <primitive object={pumpkinScene.clone()} />
        </RigidBody>
      )}
      {smoking && explosionPosition && (
        <Smoke
          position={explosionPosition}
          onComplete={() => setSmoking(false)}
        />
      )}
      {exploding && explosionPosition && (
        <Flash
          position={explosionPosition}
          color={0xffa500}
          onComplete={() => setExploding(false)}
        />
      )}
    </group>
  );
}
