import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

export interface KannonState {
  leftToRight: number;
  setLeftToRight(x: number): void;
  upDown: number;
  setUpDown(upDown: number): void;
  forceMultiplyer: number;
  setForceMultiplyer(upDown: number): void;
}
const useKannonStore = create<KannonState>()(
  subscribeWithSelector((set, get) => ({
    leftToRight: 0,
    setLeftToRight(x: number) {
      set({ leftToRight: x });
    },
    upDown: 0,
    setUpDown(upDown: number) {
      set({ upDown: upDown });
    },
    forceMultiplyer: 1,
    setForceMultiplyer(forceMultiplyer: number) {
      set({ forceMultiplyer: forceMultiplyer });
    },
  }))
);

export default useKannonStore;
