import { useRef, useEffect, useMemo, useState } from 'react';
import {
  Points,
  BufferGeometry,
  Float32BufferAttribute,
  PointsMaterial,
  TextureLoader,
  Texture,
  AdditiveBlending,
  Vector3,
} from 'three';
import { useFrame } from '@react-three/fiber';

type ExplosionProps = {
  position: Vector3;
  onComplete: () => void;
};

export default function Smoke({ position, onComplete }: ExplosionProps) {
  const smokeRef = useRef<Points>(null);
  const particles = 10; // Number of particles for both explosion and smoke
  const smokeDuration = 4; // Smoke lasts for 3 seconds
  const [smokeOpacity, setSmokeOpacity] = useState(1); // Initial smoke opacity
  const [smokeSize, setSmokeSize] = useState(0.5); // Initial smoke opacity
  const [particleTexture, setParticleTexture] = useState<Texture>(); // State for texture loading

  // Load the texture asynchronously
  useEffect(() => {
    const textureLoader = new TextureLoader();
    textureLoader.load('Textures/smoke.png', (texture) => {
      setParticleTexture(texture); // Set the texture when it's loaded
    });
  }, []);

  const smokeData = useMemo(() => {
    const geometry = new BufferGeometry();
    const positions = new Float32Array(particles * 3);
    for (let i = 0; i < particles; i++) {
      positions[i * 3] = (Math.random() - 0.5) * 1;
      positions[i * 3 + 1] = (Math.random() - 0.5) * 1;
      positions[i * 3 + 2] = (Math.random() - 0.5) * 1;
    }
    console.log(positions);

    geometry.setAttribute('position', new Float32BufferAttribute(positions, 3));
    const material = new PointsMaterial();

    return { geometry, material };
  }, []);

  // Apply the texture to the material after it has been loaded
  useEffect(() => {
    if (particleTexture && smokeRef.current) {
      const material = smokeRef.current.material as PointsMaterial;
      material.map = particleTexture; // Set the texture map
      material.alphaMap = particleTexture; // Set the alpha map
      material.needsUpdate = true; // Mark material for update
    }
  }, [particleTexture]); // Run this effect when the texture is loaded

  useFrame((state, delta) => {
    // Smoke animation (rises, grows, and fades)
    if (smokeRef.current) {
      const positions = smokeRef.current.geometry.attributes.position
        .array as Float32Array;
      for (let i = 0; i < positions.length; i += 3) {
        positions[i + 1] += delta * 0.8; // Drift upward
      }
      smokeRef.current.geometry.attributes.position.needsUpdate = true;
    }
  });

  useEffect(() => {
    let smokeInterval: number;
    // Animate smoke opacity and size over smokeDuration to fade it out
    const fadeOut = () => {
      smokeInterval = setInterval(() => {
        setSmokeOpacity((prev) => {
          const newOpacity = prev - 0.02;
          if (newOpacity <= 0) {
            clearInterval(smokeInterval);
            setTimeout(onComplete, 0); // Complete explosion and smoke
          }
          return Math.max(newOpacity, 0);
        });
        setSmokeSize((prev) => {
          const newSize = prev + 0.2;
          return newSize;
        });
      }, smokeDuration * 10);
    };

    fadeOut();
    return () => {
      clearInterval(smokeInterval);
    };
  }, [onComplete]);

  return (
    <group position={position}>
      <points
        ref={smokeRef}
        geometry={smokeData.geometry}
        material={smokeData.material}
      >
        <pointsMaterial
          attach='material'
          opacity={smokeOpacity}
          size={smokeSize}
          transparent={true}
          depthWrite={false}
          blending={AdditiveBlending}
          sizeAttenuation={true}
          color={'#787875'}
        />
      </points>
    </group>
  );
}
