import { useProgress } from '@react-three/drei';
import './LandingPage.css';
import { useEffect, useState } from 'react';
import useAudioStore, { Music } from '../../../stores/audioStore';
import useGameStateStore, { GameState } from '../../../stores/gameStateStore';

export default function LandingPage() {
  const { turnMusicOn, playMusic } = useAudioStore();
  const { setGameState } = useGameStateStore();
  const [loaded, setLoaded] = useState<boolean>(false);

  const progress = useProgress();
  useEffect(() => {
    if (progress.progress >= 100) {
      setLoaded(() => true);
    }
  }, [progress.progress]);

  const handleEnterExperience = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    musicOn: boolean
  ) => {
    e.preventDefault();

    if (musicOn) {
      turnMusicOn(GameState.intro);
    }

    setGameState(GameState.intro);
  };

  return (
    <div className='container'>
      <p className='super-text'>Dare ye' face the hordes...?</p>
      <div className='logoWrapper'>
        <img src='logo.webp' alt='Logo' className='logo' />
      </div>
      {loaded ? (
        <div className=''>
          <p className='sub-text'>Enter experince with... </p>
          <button
            className='button'
            onClick={(e) => handleEnterExperience(e, true)}
          >
            Music On
          </button>
          <button
            className='button'
            onClick={(e) => handleEnterExperience(e, false)}
          >
            Music Off
          </button>
        </div>
      ) : (
        <>
          <p className='sub-text'>Loading...</p>
          <div className='loading-container'>
            <div
              className='progress-bar'
              style={{ width: `${progress.progress}%` }}
            />
          </div>
        </>
      )}
    </div>
  );
}
