import { useGLTF } from '@react-three/drei';
import { Material, Mesh } from 'three';

interface ForestScene {
  nodes: {
    pine: Mesh;
    'pine-crooked': Mesh;
    'pine-fall': Mesh;
    'pine-fall-crooked': Mesh;
    road: Mesh;
    road001: Mesh;
    road002: Mesh;
    road003: Mesh;
    road004: Mesh;
    road005: Mesh;
    road006: Mesh;
    road007: Mesh;
    road008: Mesh;
    road009: Mesh;
    road010: Mesh;
    road011: Mesh;
    road012: Mesh;
    road013: Mesh;
    road014: Mesh;
    road015: Mesh;
    rocks: Mesh;
    'rocks-tall': Mesh;
    'trunk-long': Mesh;
    trunk: Mesh;
    'pine-crooked001': Mesh;
    'pine-fall001': Mesh;
    trunk001: Mesh;
    trunk002: Mesh;
    trunk003: Mesh;
    pine001: Mesh;
    pine002: Mesh;
    pine003: Mesh;
    rocks001: Mesh;
    'pine-crooked002': Mesh;
    'pine-fall-crooked001': Mesh;
    pine004: Mesh;
    pine005: Mesh;
    pine006: Mesh;
    trunk004: Mesh;
    pine007: Mesh;
    pine008: Mesh;
    pine009: Mesh;
    'pine-fall002': Mesh;
  };
  materials: {
    colormap: Material;
  };
}

export default function Landscape() {
  const { nodes, materials } = useGLTF(
    'models/landscape.glb'
  ) as unknown as ForestScene;
  return (
    <group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.pine.geometry}
        material={materials.colormap}
        position={[11.175, 0, -28.2]}
        scale={[1, 1.342, 1]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes['pine-crooked'].geometry}
        material={materials.colormap}
        position={[-7.429, 0, -13.519]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes['pine-fall'].geometry}
        material={materials.colormap}
        position={[-12.884, 0, -21.622]}
        scale={[1.152, 1.147, 1.152]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes['pine-fall-crooked'].geometry}
        material={materials.colormap}
        position={[8.563, 0, -8.663]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rocks.geometry}
        material={materials.colormap}
        position={[-3.682, 0, -4.335]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes['rocks-tall'].geometry}
        material={materials.colormap}
        position={[6.787, 0, -19.762]}
        scale={[1, 0.474, 1]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes['trunk-long'].geometry}
        material={materials.colormap}
        position={[-9.865, 0, -28.598]}
        rotation={[0, -1.402, 0]}
        scale={[1.395, 1, 1.395]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.trunk.geometry}
        material={materials.colormap}
        position={[7.603, 0, -14.648]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes['pine-crooked001'].geometry}
        material={materials.colormap}
        position={[-10.418, 0, -13.808]}
        scale={0.806}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes['pine-fall001'].geometry}
        material={materials.colormap}
        position={[-8.469, 0, -12.549]}
        scale={[0.913, 0.677, 0.913]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.trunk001.geometry}
        material={materials.colormap}
        position={[-8.238, 0, -18.41]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.trunk002.geometry}
        material={materials.colormap}
        position={[-10.792, 0, -26.995]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.trunk003.geometry}
        material={materials.colormap}
        position={[8.261, 0, -12.237]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.pine001.geometry}
        material={materials.colormap}
        position={[12.739, 0, -26.925]}
        scale={1.367}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.pine002.geometry}
        material={materials.colormap}
        position={[12.29, 0, -30.136]}
        scale={0.745}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.pine003.geometry}
        material={materials.colormap}
        position={[10.012, 0, -15.122]}
        scale={[1.222, 0.89, 1.222]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.rocks001.geometry}
        material={materials.colormap}
        position={[-7.028, 0, -20.435]}
        rotation={[-Math.PI, 0.56, -Math.PI]}
        scale={[1.208, 1, 1.208]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes['pine-crooked002'].geometry}
        material={materials.colormap}
        position={[-12.353, 0, -38.71]}
        scale={1.027}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes['pine-fall-crooked001'].geometry}
        material={materials.colormap}
        position={[11.282, 0, -10.238]}
        scale={1.395}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.pine004.geometry}
        material={materials.colormap}
        position={[10.599, 0, -7.43]}
        scale={1.099}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.pine005.geometry}
        material={materials.colormap}
        position={[8.21, 0, -19.315]}
        scale={0.737}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.pine006.geometry}
        material={materials.colormap}
        position={[10.657, 0, -44.9]}
        scale={1.367}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.trunk004.geometry}
        material={materials.colormap}
        position={[-5.826, 0, -7.756]}
        rotation={[0, 0.885, 0]}
        scale={[1.879, 1.47, 1.879]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.pine007.geometry}
        material={materials.colormap}
        position={[-13.804, 0, -31.728]}
        rotation={[0, -1.064, 0]}
        scale={1.181}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.pine008.geometry}
        material={materials.colormap}
        position={[-11.68, 0, -24.224]}
        rotation={[Math.PI, -1.148, Math.PI]}
        scale={[1.96, 1.35, 1.96]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.pine009.geometry}
        material={materials.colormap}
        position={[-14.418, 0, -17.388]}
        rotation={[0, -1.064, 0]}
        scale={0.855}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes['pine-fall002'].geometry}
        material={materials.colormap}
        position={[-16.48, 0, -24.498]}
        scale={[1.223, 1.404, 1.223]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.road001.geometry}
        material={materials.colormap}
        position={[-0.574, 0, -5.082]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.road002.geometry}
        material={materials.colormap}
        position={[0.515, 0, -3.996]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.road003.geometry}
        material={materials.colormap}
        position={[0.59, 0, -5.766]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.road004.geometry}
        material={materials.colormap}
        position={[-0.33, 0, -8.447]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.road005.geometry}
        material={materials.colormap}
        position={[0.635, 0, -11.086]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.road006.geometry}
        material={materials.colormap}
        position={[-0.181, 0, -12.544]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.road007.geometry}
        material={materials.colormap}
        position={[-0.436, 0, -10.362]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.road008.geometry}
        material={materials.colormap}
        position={[0.651, 0, -14.065]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.road009.geometry}
        material={materials.colormap}
        position={[-0.488, 0, -16.756]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.road010.geometry}
        material={materials.colormap}
        position={[-0.306, 0, -20.273]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.road011.geometry}
        material={materials.colormap}
        position={[0.319, 0, -18.566]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.road012.geometry}
        material={materials.colormap}
        position={[-0.099, -0.043, -26.144]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.road013.geometry}
        material={materials.colormap}
        position={[0.633, -0.043, -21.132]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.road014.geometry}
        material={materials.colormap}
        position={[0.709, -0.043, -24.05]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.road015.geometry}
        material={materials.colormap}
        position={[-0.463, -0.043, -22.881]}
      />
    </group>
  );
}
