import { useEffect, MouseEvent } from 'react';
import './ContextModal.css';
import useGameStateStore, { GameState } from '../../../stores/gameStateStore';
import IntroContent from './IntroContent';
import GameOverContent from './GameOverContent';
import useAudioStore, { Music } from '../../../stores/audioStore';
import useIsMobile from '../../hooks/useIsMobile';
import useDeviceOrientation from '../../hooks/useDeviceOrientation';

export default function ContextModal() {
  const { gameState, setGameState } = useGameStateStore();
  const { playMusic: turnMusicOn } = useAudioStore();

  useEffect(() => {
    const overlay = document.getElementById('overlay') as HTMLElement;
    if (gameState === GameState.intro || gameState === GameState.gameOver) {
      overlay.style.display = 'flex';
    } else {
      overlay.style.display = 'none';
    }
  }, [gameState]);

  const handleClose = (e: MouseEvent, startTutorial: boolean) => {
    e.preventDefault();
    if (gameState == GameState.intro && startTutorial) {
      setGameState(GameState.tutorial);
      return;
    }
    setGameState(GameState.inGame);
    turnMusicOn(Music.inGame);
  };

  return (
    <div id='overlay'>
      <main>
        {gameState === GameState.intro && <IntroContent />}
        {gameState === GameState.gameOver && <GameOverContent />}
        <div className='modal-footer'>
          <button onClick={(e) => handleClose(e, true)}>
            {gameState == GameState.intro ? "Let's go!" : 'Try again'}
          </button>
          {gameState == GameState.intro && (
            <button onClick={(e) => handleClose(e, false)}>
              Skip tutorial
            </button>
          )}
        </div>
      </main>
    </div>
  );
}
