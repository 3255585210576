import { useRef, useEffect, useMemo } from 'react';
import {
  Points,
  BufferGeometry,
  Float32BufferAttribute,
  PointsMaterial,
  Vector3,
} from 'three';
import { useFrame } from '@react-three/fiber';

type PumpkinBitsProps = {
  position: Vector3;
  color: number;
  onComplete: () => void;
};

export default function Flash({
  position,
  color,
  onComplete,
}: PumpkinBitsProps) {
  const pointsRef = useRef<Points>(null);
  const particles = 100; // Number of particles

  const { geometry, material } = useMemo(() => {
    const geometry = new BufferGeometry();
    const positions = new Float32Array(particles * 3); // x, y, z per particle

    // Randomly place particles within a small sphere
    for (let i = 0; i < particles; i++) {
      positions[i * 3] = (Math.random() - 0.5) * 1;
      positions[i * 3 + 1] = (Math.random() - 0.5) * 1;
      positions[i * 3 + 2] = (Math.random() - 0.5) * 1;
    }

    geometry.setAttribute('position', new Float32BufferAttribute(positions, 3));

    const material = new PointsMaterial({
      color: color, // Explosion color (orange)
      size: 0.35,
      sizeAttenuation: true,
    });

    return { geometry, material };
  }, []);

  const speed = 20; // Set a constant speed value
  useFrame((state, delta) => {
    if (pointsRef.current) {
      const positions = pointsRef.current.geometry.attributes.position
        .array as Float32Array;

      for (let i = 0; i < positions.length; i += 3) {
        positions[i] += positions[i] * speed * delta; // X-axis
        positions[i + 1] += positions[i + 1] * speed * delta; // Y-axis
        positions[i + 2] += positions[i + 2] * speed * delta; // Z-axis
      }

      pointsRef.current.geometry.attributes.position.needsUpdate = true;
    }
  });

  useEffect(() => {
    // Remove explosion after a short duration
    const timeout = setTimeout(onComplete, 300); // 1 second explosion

    return () => clearTimeout(timeout);
  }, [onComplete]);

  return (
    <points
      ref={pointsRef}
      geometry={geometry}
      material={material}
      position={position}
    />
  );
}
