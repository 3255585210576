import useGameStateStore from '../../../stores/gameStateStore';
import './modalContent.css';

export default function GameOverContent() {
  const { score } = useGameStateStore();
  return (
    <div className='modal-content'>
      <h1 className='modal-header'>What a score! {score} points!</h1>
      <div className='modal-body'>
        <p>Alas... The horde has broken through!</p>
        <p>
          You fought bravely, though and were able to strike down {score} foul
          beasts! Come the 1st of November, we shall celebrate your victory in
          the local tavern!
        </p>
      </div>
    </div>
  );
}
