import { useRef, useState } from 'react';
import './Options.css';
import useAudioStore from '../../../stores/audioStore';
import useGameStateStore, { GameState } from '../../../stores/gameStateStore';

export default function Options() {
  const { musicOn, soundsOn, toggleSounds, turnMusicOff, turnMusicOn } =
    useAudioStore();
  const { gameState } = useGameStateStore();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const musicRef = useRef<HTMLAudioElement>(null!);

  const handleMusicToggle = () => {
    if (musicOn) {
      turnMusicOff();
    } else {
      turnMusicOn(gameState);
    }
  };

  const handleSoundToggle = () => {
    toggleSounds();
  };

  const handleMenuClick = () => {
    setMenuOpen((current) => !current);
  };

  return (
    <>
      <audio
        id='in-game-music'
        ref={musicRef}
        src='/audio/music/in_game_background.mp3'
        autoPlay={false}
        loop
      ></audio>
      <audio
        id='intro-music'
        ref={musicRef}
        src='/audio/music/intro_background.mp3'
        autoPlay={false}
        loop
      ></audio>
      {gameState !== GameState.landingPage && (
        <div id='hud' className='menu'>
          <button onClick={() => handleMenuClick()} className='circle '>
            <img
              className='icon'
              id='menu-icon'
              src={`${menuOpen ? '/icons/close.svg' : '/icons/menu.svg'}`}
              alt='look'
            ></img>
          </button>
          <div className={`menu-items ${menuOpen ? 'open' : ''}`}>
            <button onClick={handleMusicToggle} className='menu-item'>
              <img
                className='icon menu-icon'
                src={musicOn ? '/icons/music_off.svg' : '/icons/music_on.svg'}
                alt='talk'
              ></img>
              <p className='menu-item-text'>
                turn music {musicOn ? 'off' : 'on'}
              </p>
            </button>
            <button onClick={handleSoundToggle} className='menu-item'>
              <img
                className='icon'
                src={soundsOn ? '/icons/sound_off.svg' : '/icons/sound_on.svg'}
                alt='talk'
              ></img>
              <p className='menu-item-text'>
                turn sound {soundsOn ? 'off' : 'on'}
              </p>
            </button>
          </div>
        </div>
      )}
    </>
  );
}
