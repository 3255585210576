import { useGLTF } from '@react-three/drei';
import { useEffect, useRef, useState } from 'react';
import { Mesh, Object3DEventMap, Vector3, Group, Material } from 'three';
import Enemy from './Enemy';
import useGameStateStore, { GameState } from '../../stores/gameStateStore';
import { interactionGroups, RigidBody } from '@react-three/rapier';
import useAudioStore from '../../stores/audioStore';

export interface EnemyData {
  position: Vector3;
  name: string;
  model: Group<Object3DEventMap>;
  points: number;
  enemyType: EnemyType;
}

export enum EnemyType {
  skeleton = 'skeleton',
  ghost = 'ghost',
  zombie = 'zombie',
}

interface SpawnData {
  position: Vector3;
}

interface Graveyard {
  nodes: {
    graveyard_ground: Mesh;
    coffin: Mesh;
    'coffin-old': Mesh;
    cross: Mesh;
    'cross-column': Mesh;
    'cross-wood': Mesh;
    crypt: Mesh;
    'crypt-large': Mesh;
    'crypt-large-roof': Mesh;
    'gravestone-bevel': Mesh;
    'gravestone-broken': Mesh;
    'gravestone-cross': Mesh;
    'gravestone-cross-large': Mesh;
    'gravestone-debris': Mesh;
    'gravestone-decorative': Mesh;
    'gravestone-roof': Mesh;
    'gravestone-round': Mesh;
    'gravestone-wide': Mesh;
    'pillar-obelisk': Mesh;
    crypt001: Mesh;
    'iron-fence': Mesh;
    'iron-fence001': Mesh;
    'iron-fence002': Mesh;
    'iron-fence-damaged': Mesh;
    'iron-fence-damaged001': Mesh;
    'iron-fence-damaged002': Mesh;
    'iron-fence-damaged003': Mesh;
    'iron-fence-damaged004': Mesh;
    'iron-fence-damaged005': Mesh;
    'iron-fence-damaged006': Mesh;
    'iron-fence-damaged007': Mesh;
    'iron-fence-damaged008': Mesh;
    'iron-fence-damaged009': Mesh;
    'iron-fence-damaged010': Mesh;
    spawn_grave: Mesh;
    spawn_grave001: Mesh;
    spawn_grave002: Mesh;
    spawn_grave003: Mesh;
    spawn_grave004: Mesh;
    spawn_grave005: Mesh;
    spawn_grave006: Mesh;
    spawn_candles: Mesh;
    spawn_candles001: Mesh;
    spawn_candles002: Mesh;
  };
  materials: {
    dirt: Material;
    colormap: Material;
  };
}
export default function Graveyard() {
  const { nodes, materials } = useGLTF(
    'models/graveyard.glb'
  ) as unknown as Graveyard;

  const ghost = useGLTF('models/ghost.glb');
  const skeleton = useGLTF('models/skeleton.glb');
  const zombie = useGLTF('models/zombie.glb');

  const [enemies, setEnemies] = useState<EnemyData[]>([]);
  const enemyCount = useRef(0);

  const spawnMap = useRef(new Map<Number, SpawnData>());
  const gameState = useGameStateStore((state) => state.gameState);

  const { addScore } = useGameStateStore();
  const { handleSpawnSound } = useAudioStore();
  let spawnTimeout = useRef<number>(0);
  const startTime = useRef<Date>(new Date());
  const max = useRef(8); // initial max spawn time set to 7 seconds

  // load spawn data
  useEffect(() => {
    setSpawnPoint(nodes.spawn_grave);
    setSpawnPoint(nodes.spawn_grave001);
    setSpawnPoint(nodes.spawn_grave002);
    setSpawnPoint(nodes.spawn_grave003);
    setSpawnPoint(nodes.spawn_grave004);
    setSpawnPoint(nodes.spawn_grave005);
    setSpawnPoint(nodes.spawn_grave006);
    setSpawnPoint(nodes.spawn_candles);
    setSpawnPoint(nodes.spawn_candles001);
    setSpawnPoint(nodes.spawn_candles002);
  }, []);

  const setSpawnPoint = (node: Mesh) => {
    const pos = new Vector3();
    node.getWorldPosition(pos);
    const nextKey = spawnMap.current.size + 1;
    spawnMap.current.set(nextKey, {
      position: pos,
    });
  };

  const spawnEnemy = () => {
    // return;
    console.log('GAME STATE', gameState);
    if (gameState != GameState.inGame) return;
    handleSpawnSound();
    enemyCount.current++;
    const randSpawnKey = Math.floor(Math.random() * spawnMap.current.size) + 1;
    const spawnPosition = spawnMap.current.get(
      randSpawnKey
    ) as unknown as SpawnData;

    const randEnemyKey = Math.floor(Math.random() * 3) + 1;

    let model: Group<Object3DEventMap>;
    let name: string;

    if (randEnemyKey == 1) {
      model = skeleton.scene.clone();
      name = 'skeleton';
    } else if (randEnemyKey == 2) {
      model = zombie.scene.clone();
      name = 'zombie';
    } else {
      // implicit if key == 3
      model = ghost.scene.clone();
      name = 'ghost';
    }

    const spawnTime = Date.now().toString();
    const enemyName = `enemy_${name}_${enemyCount.current}_${spawnTime}`;
    setEnemies((prevEnemies) => [
      ...prevEnemies,
      {
        position: spawnPosition.position,
        name: enemyName,
        model: model,
        points: 1,
        enemyType: name as EnemyType,
      },
    ]);

    // spawn random time between 2 and 8 seconds
    const min = 2;
    const randomEnemySpawnTime = Math.floor(
      Math.random() * (max.current - min + 1) + min
    );
    console.log(
      `Spawning ${enemyName} in ${randomEnemySpawnTime * 1000} seconds`
    );
    spawnTimeout.current = setTimeout(
      () => spawnEnemy(),
      randomEnemySpawnTime * 1000
    );
  };

  const removeEnemy = (name: string, wasDefeated: boolean) => {
    setEnemies((prevEnemies) =>
      prevEnemies.filter((enemy) => enemy.name !== name)
    );
    if (wasDefeated) {
      addScore(name);
    }
  };

  // // Decrement `max` every 30 seconds until it reaches 2
  useEffect(() => {
    if (gameState === GameState.inGame) {
      const interval = setInterval(() => {
        if (max.current > 3) {
          console.log(
            'increasing difficulity. new spawn max:',
            max.current - 1
          );
          return max.current--;
        } else {
          clearInterval(interval);
        }
      }, 30000);
      return () => clearInterval(interval);
    }
  }, [gameState]);

  useEffect(() => {
    if (gameState === GameState.inGame) {
      max.current = 8;
      clearTimeout(spawnTimeout.current);
      startTime.current = new Date();
      spawnEnemy();
      return;
    }

    if (gameState === GameState.gameOver) {
      max.current = 8;
      clearTimeout(spawnTimeout.current);
    }

    return () => clearTimeout(spawnTimeout.current);
  }, [gameState]);

  return (
    <>
      {enemies.map((enemy) => (
        <Enemy key={enemy.name} data={enemy} onRemove={removeEnemy} />
      ))}
      <group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.coffin.geometry}
          material={materials.colormap}
          position={[-3.437, 2.82, -38.908]}
          rotation={[-0.024, 0.55, 0.144]}
          scale={1.255}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['coffin-old'].geometry}
          material={materials.colormap}
          position={[-3.437, 2.82, -38.908]}
          rotation={[-0.024, 0.55, 0.144]}
          scale={1.255}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.cross.geometry}
          material={materials.colormap}
          position={[4.995, 2.646, -40.064]}
          rotation={[-0.003, -0.014, -0.193]}
          scale={2.491}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['cross-column'].geometry}
          material={materials.colormap}
          position={[-4.365, 2.555, -43.255]}
          rotation={[-0.158, -0.044, 0.111]}
          scale={1.899}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['cross-wood'].geometry}
          material={materials.colormap}
          position={[-0.304, 2.648, -35.313]}
          rotation={[0.197, 0.012, 0.005]}
          scale={1.638}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.crypt.geometry}
          material={materials.colormap}
          position={[-7.263, 1.542, -35.397]}
          rotation={[0.028, 0.58, 0.441]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['crypt-large'].geometry}
          material={materials.colormap}
          position={[6.974, 1.692, -36.19]}
          rotation={[0.027, -0.576, -0.393]}
          scale={1.266}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes['crypt-large-roof'].geometry}
            material={materials.colormap}
            position={[-0.002, 1.006, 0.011]}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['gravestone-bevel'].geometry}
          material={materials.colormap}
          position={[-4.515, 2.159, -34.046]}
          rotation={[0.298, 0.378, 0.211]}
          scale={2.237}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['gravestone-broken'].geometry}
          material={materials.colormap}
          position={[-1.838, 2.977, -38.743]}
          rotation={[0.049, 0.002, 0.068]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['gravestone-cross'].geometry}
          material={materials.colormap}
          position={[3.4, 2.743, -37.222]}
          rotation={[0.095, 0.006, 0.031]}
          scale={1.403}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['gravestone-cross-large'].geometry}
          material={materials.colormap}
          position={[-1.339, 2.795, -43.505]}
          rotation={[-0.1, 0.412, 0.031]}
          scale={1.573}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['gravestone-debris'].geometry}
          material={materials.colormap}
          position={[4.039, 1.759, -33.074]}
          rotation={[0.306, -0.007, -0.178]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['gravestone-decorative'].geometry}
          material={materials.colormap}
          position={[0.971, 2.942, -42.463]}
          rotation={[-0.08, 0.001, -0.026]}
          scale={1.765}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['gravestone-roof'].geometry}
          material={materials.colormap}
          position={[0.887, 2.978, -38.653]}
          rotation={[0.027, -0.023, 0.187]}
          scale={1.244}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['gravestone-round'].geometry}
          material={materials.colormap}
          position={[1.993, 2.829, -37.265]}
          rotation={[0.113, -0.018, -0.069]}
          scale={1.648}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['gravestone-wide'].geometry}
          material={materials.colormap}
          position={[-2.394, 2.597, -35.641]}
          rotation={[0.163, 0.005, 0.111]}
          scale={1.412}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['pillar-obelisk'].geometry}
          material={materials.colormap}
          position={[2.552, 2.607, -44.212]}
          rotation={[-0.152, 0.042, -0.12]}
          scale={3.333}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.crypt001.geometry}
          material={materials.colormap}
          position={[-6.394, 2.259, -37.776]}
          rotation={[0.091, -0.079, 0.286]}
          scale={1.555}
        />
        <RigidBody
          colliders={'trimesh'}
          type='fixed'
          collisionGroups={interactionGroups([0], [0, 1, 2])}
          name='grave_mound'
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.graveyard_ground.geometry}
            material={materials.dirt}
            position={[0, 2.094, -40]}
          />
        </RigidBody>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['iron-fence'].geometry}
          material={materials.colormap}
          position={[6.671, 0.287, -30.908]}
          rotation={[0.442, 0.517, -0.237]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['iron-fence-damaged'].geometry}
          material={materials.colormap}
          position={[10.494, 0.428, -37.01]}
          rotation={[1.043, 1.126, -0.986]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['iron-fence-damaged001'].geometry}
          material={materials.colormap}
          position={[9.986, 0.37, -35.235]}
          rotation={[0.425, 1.069, -0.375]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['iron-fence-damaged002'].geometry}
          material={materials.colormap}
          position={[9.286, 0.351, -33.848]}
          rotation={[0.643, 0.895, -0.507]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['iron-fence-damaged003'].geometry}
          material={materials.colormap}
          position={[8.508, 0.352, -32.784]}
          rotation={[0.577, 0.812, -0.457]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['iron-fence-damaged004'].geometry}
          material={materials.colormap}
          position={[5.635, 0.33, -30.405]}
          rotation={[0.45, 0.362, -0.199]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['iron-fence-damaged005'].geometry}
          material={materials.colormap}
          position={[7.708, -0.069, -32.091]}
          rotation={[1.882, -0.295, -0.842]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['iron-fence001'].geometry}
          material={materials.colormap}
          position={[-9.958, 0.528, -36.198]}
          rotation={[0.814, -1.034, 0.748]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['iron-fence-damaged006'].geometry}
          material={materials.colormap}
          position={[-5.952, 0.343, -30.554]}
          rotation={[0.48, -0.396, 0.211]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['iron-fence-damaged007'].geometry}
          material={materials.colormap}
          position={[-8.236, 0.479, -33.037]}
          rotation={[0.62, -0.902, 0.49]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['iron-fence-damaged009'].geometry}
          material={materials.colormap}
          position={[-9.291, 0.538, -34.793]}
          rotation={[0.758, -0.936, 0.659]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['iron-fence-damaged010'].geometry}
          material={materials.colormap}
          position={[-10.56, 0.554, -37.963]}
          rotation={[0.944, -1.095, 0.861]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes['iron-fence002'].geometry}
          material={materials.colormap}
          position={[-7.267, 0.485, -32.026]}
          rotation={[0.529, -0.754, 0.325]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.spawn_grave.geometry}
          material={materials.colormap}
          position={[3.243, 2.689, -35.884]}
          rotation={[0.094, -0.004, -0.129]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.spawn_grave001.geometry}
          material={materials.colormap}
          position={[-2.468, 2.437, -34.397]}
          rotation={[0.163, 0.004, 0.111]}
          scale={1.28}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.spawn_grave004.geometry}
          material={materials.colormap}
          position={[0.743, 2.784, -37.487]}
          rotation={[0.133, 0.027, -0.011]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.spawn_grave003.geometry}
          material={materials.colormap}
          position={[-4.011, 1.823, -32.856]}
          rotation={[0.274, 0.478, 0.221]}
          scale={1.408}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.spawn_grave002.geometry}
          material={materials.colormap}
          position={[-0.296, 2.452, -34.337]}
          rotation={[0.197, 0.001, 0.005]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.spawn_grave005.geometry}
          material={materials.colormap}
          position={[1.969, 2.774, -35.973]}
          rotation={[0.114, 0.002, -0.069]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.spawn_grave006.geometry}
          material={materials.colormap}
          position={[4.161, 1.353, -32.112]}
          rotation={[0.405, -0.04, -0.183]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.spawn_candles.geometry}
          material={materials.colormap}
          position={[6.365, 1.909, -35.022]}
          rotation={[0.236, -0.039, -0.327]}
          scale={[0.5, 1, 0.5]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.spawn_candles001.geometry}
          material={materials.colormap}
          position={[-6.651, 1.544, -34.478]}
          rotation={[0.263, 0.049, 0.365]}
          scale={[0.5, 1, 0.5]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.spawn_candles002.geometry}
          material={materials.colormap}
          position={[-5.534, 2.481, -36.738]}
          rotation={[0.065, 0.009, 0.285]}
          scale={[0.5, 1, 0.5]}
        />
      </group>
    </>
  );
}
