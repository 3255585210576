import {
  CameraShake,
  KeyboardControlsEntry,
  Text3D,
  useGLTF,
  useKeyboardControls,
} from '@react-three/drei';
import { Material, Mesh, Vector3 } from 'three';
import useKannonStore from '../../../stores/kannonStore';
import { ThreeEvent } from '@react-three/fiber';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSpring, a } from '@react-spring/three';
import useAudioStore, { Sounds } from '../../../stores/audioStore';
import useGameStateStore, { GameState } from '../../../stores/gameStateStore';
import { Controls } from '../Kannon';

interface KannonModelProps {
  cannonBodyRef: any;
  cannonRef: any;
  pumpkinSpawnPointRef: any;
  fire(): void;
}

interface kannon {
  nodes: {
    turn_left_btn: Mesh;
    turn_right_btn: Mesh;
    tilt_down_btn: Mesh;
    tilt_up_btn: Mesh;
    power_up_btn: Mesh;
    power_down_btn: Mesh;
    control_box: Mesh;
    kannon_body: Mesh;
    kannon_base: Mesh;
    pumpkin_spawn_point: Mesh;
    fire_btn: Mesh;
    power_pip_1: Mesh;
    power_pip_2: Mesh;
    power_pip_3: Mesh;
    power_pip_4: Mesh;
    life_crystal_1: Mesh;
    life_crystal_2: Mesh;
    life_crystal_3: Mesh;
    life_crystal_4: Mesh;
    life_crystal_5: Mesh;
    score_target: Mesh;
    Text: Mesh;
  };
  materials: {
    copper: Material;
    wood: Material;
    metal: Material;
    red: Material;
    pip_light: Material;
    pip_dark: Material;
  };
}

export default function KannonModel(props: KannonModelProps) {
  useGLTF.preload('models/kannon.glb');
  const { nodes, materials } = useGLTF(
    'models/kannon.glb'
  ) as unknown as kannon;
  // const [reloading, setReloading] = useState(false);
  const isReloading = useRef(false);
  // todo implement keyboard controls to manage kannon movement, power up/down, and firing
  const [sub, get] = useKeyboardControls<Controls>();

  // Use spring to animate the cannon's position
  const [spring, api] = useSpring(() => ({
    position: [0, 1.048, 0],
    config: { duration: 200 }, // Default duration of 200ms
  }));

  const { playerHealth, score, gameState } = useGameStateStore();
  // const scoreTargetRef = useRef<Mesh>(null!);
  // const [scoreLocation, setScoreLocation] = useState<Vector3>(new Vector3());
  // useEffect(() => {
  //   if (scoreTargetRef.current) {
  //     setScoreLocation(scoreTargetRef.current.position);
  //   }
  // }, [scoreTargetRef.current]);

  const {
    leftToRight,
    setLeftToRight,
    upDown,
    setUpDown,
    forceMultiplyer,
    setForceMultiplyer,
  } = useKannonStore();

  const { playSound } = useAudioStore();

  const adjustCannon = (control: Controls) => {
    const tenthPercent = Number((0.1).toFixed(1));
    const wholePercent = Number((1).toFixed(1));

    switch (control) {
      case Controls.left:
        if (leftToRight > -1) setLeftToRight(leftToRight - tenthPercent);
        break;
      case Controls.right:
        if (leftToRight < 1) setLeftToRight(leftToRight + tenthPercent);
        break;
      case Controls.up:
        if (upDown < 0.9) setUpDown(upDown + tenthPercent);
        break;
      case Controls.down:
        if (upDown > -0.2) setUpDown(upDown - tenthPercent);
        break;
      case Controls.powerUp:
        if (forceMultiplyer < 4)
          setForceMultiplyer(forceMultiplyer + wholePercent);
        break;
      case Controls.powerDown:
        if (forceMultiplyer > 1)
          setForceMultiplyer(forceMultiplyer - wholePercent);
        break;
      case Controls.fire:
        handleFire();
        break;
    }
  };

  const handleFire = () => {
    if (!isReloading.current && gameState === GameState.inGame) {
      playSound(Sounds.boom);
      props.fire();

      let maxEndY = 1.048;
      let maxEndZ = 1;
      maxEndY = maxEndZ - upDown;
      api.start({ position: [0, maxEndY, maxEndZ], config: { duration: 100 } });
      setTimeout(() => {
        api.start({ position: [0, 1.048, 0], config: { duration: 1100 } });
      }, 300);

      isReloading.current = true;
      setTimeout(() => {
        isReloading.current = false;
      }, 1500);
    }
  };

  useEffect(() => {
    const unsub = sub((state) => {
      if (gameState !== GameState.inGame) return;

      if (state[Controls.up]) adjustCannon(Controls.up);
      if (state[Controls.down]) adjustCannon(Controls.down);
      if (state[Controls.left]) adjustCannon(Controls.left);
      if (state[Controls.right]) adjustCannon(Controls.right);
      if (state[Controls.powerUp]) adjustCannon(Controls.powerUp);
      if (state[Controls.powerDown]) adjustCannon(Controls.powerDown);
      if (state[Controls.fire]) adjustCannon(Controls.fire);
    });

    return () => unsub();
  }, [leftToRight, upDown, forceMultiplyer, gameState]);

  const handleButtonClick = (e: ThreeEvent<MouseEvent>) => {
    e.stopPropagation();
    if (gameState !== GameState.inGame) return;
    const tenthPercent = Number((0.1).toFixed(1));
    const wholePercent = Number((1).toFixed(1));
    playSound(Sounds.buttonClick);
    // cheap button click animation
    e.eventObject.position.y = e.eventObject.position.y - 0.02;
    setTimeout(() => {
      e.eventObject.position.y = e.eventObject.position.y + 0.02;
    }, 100);

    if (e.eventObject.name == 'turn_left_btn' && leftToRight > -1) {
      setLeftToRight(leftToRight - tenthPercent);
    }
    if (e.eventObject.name == 'turn_right_btn' && leftToRight < 1) {
      setLeftToRight(leftToRight + tenthPercent);
    }
    if (e.eventObject.name == 'tilt_up_btn' && upDown < 0.9) {
      setUpDown(upDown + tenthPercent);
    }
    if (e.eventObject.name == 'tilt_down_btn' && upDown > -0.2) {
      setUpDown(upDown - tenthPercent);
    }
    if (e.eventObject.name == 'power_up_btn' && forceMultiplyer < 4) {
      setForceMultiplyer(forceMultiplyer + wholePercent);
    }
    if (e.eventObject.name == 'power_down_btn' && forceMultiplyer > 1) {
      setForceMultiplyer(forceMultiplyer - wholePercent);
    }
    if (e.eventObject.name == 'fire_btn' && !isReloading.current) {
      handleFire();
      // playSound(Sounds.boom);
      // props.fire();
      // // TODO see about making this less extreme based on forceMultiplyer
      // let maxEndY = 1.048;
      // let maxEndZ = 1;
      // maxEndY = maxEndZ - upDown;
      // api.start({ position: [0, maxEndY, maxEndZ], config: { duration: 100 } }); // Move along both axes
      // setTimeout(() => {
      //   api.start({ position: [0, 1.048, 0], config: { duration: 1100 } }); // Return to original
      // }, 300);

      // isReloading.current = true;
      // setTimeout(() => {
      //   isReloading.current = false;
      // }, 1500);
    }
    console.log('LTR', leftToRight, 'UD', upDown, 'FM', forceMultiplyer);
  };

  return (
    <group position={[0, 0, 0]}>
      {isReloading.current && (
        <CameraShake
          maxYaw={0.1} // Max amount camera can yaw in either direction
          maxPitch={0.1} // Max amount camera can pitch in either direction
          maxRoll={0.1} // Max amount camera can roll in either direction
          yawFrequency={2} // Frequency of the the yaw rotation
          pitchFrequency={2} // Frequency of the pitch rotation
          rollFrequency={2} // Frequency of the roll rotation
          intensity={0.2 * forceMultiplyer} // initial intensity of the shake
          decayRate={0.65} // if decay = true this is the rate at which intensity will reduce at />
          decay={true}
        />
      )}
      <mesh
        castShadow
        receiveShadow
        onClick={(e) => {
          handleButtonClick(e);
        }}
        name='turn_left_btn'
        geometry={nodes.turn_left_btn.geometry}
        material={materials.copper}
        position={[1.465, 0.69, 0.69]}
        rotation={[Math.PI / 4, 0, 0]}
        scale={0.127}
      />
      <mesh
        castShadow
        receiveShadow
        onClick={(e) => {
          handleButtonClick(e);
        }}
        name='turn_right_btn'
        geometry={nodes.turn_right_btn.geometry}
        material={materials.copper}
        position={[1.856, 0.69, 0.69]}
        rotation={[-2.356, 0, -Math.PI]}
        scale={0.127}
      />
      <mesh
        castShadow
        receiveShadow
        onClick={(e) => {
          handleButtonClick(e);
        }}
        name='tilt_down_btn'
        geometry={nodes.tilt_down_btn.geometry}
        material={materials.copper}
        position={[1.66, 0.551, 0.828]}
        rotation={[Math.PI / 4, Math.PI / 2, 0]}
        scale={0.127}
      />
      <mesh
        castShadow
        receiveShadow
        onClick={(e) => {
          handleButtonClick(e);
        }}
        name='tilt_up_btn'
        geometry={nodes.tilt_up_btn.geometry}
        material={materials.copper}
        position={[1.66, 0.828, 0.551]}
        rotation={[Math.PI / 4, -Math.PI / 2, 0]}
        scale={0.127}
      />
      <mesh
        castShadow
        receiveShadow
        onClick={(e) => {
          handleButtonClick(e);
        }}
        name='power_down_btn'
        geometry={nodes.power_down_btn.geometry}
        material={materials.copper}
        position={[2.41, 0.551, 0.828]}
        rotation={[Math.PI / 4, Math.PI / 2, 0]}
        scale={0.127}
      />
      <mesh
        castShadow
        receiveShadow
        onClick={(e) => {
          handleButtonClick(e);
        }}
        name='power_up_btn'
        geometry={nodes.power_up_btn.geometry}
        material={materials.copper}
        position={[2.41, 0.828, 0.551]}
        rotation={[Math.PI / 4, -Math.PI / 2, 0]}
        scale={0.127}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.power_pip_1.geometry}
        material={materials.pip_light}
        position={[2.41, 0.747, 0.632]}
        rotation={[Math.PI / 4, Math.PI / 2, 0]}
        scale={0.098}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.power_pip_2.geometry}
        material={
          forceMultiplyer > 1 ? materials.pip_light : materials.pip_dark
        }
        position={[2.41, 0.747, 0.632]}
        rotation={[Math.PI / 4, Math.PI / 2, 0]}
        scale={0.098}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.power_pip_3.geometry}
        material={
          forceMultiplyer > 2 ? materials.pip_light : materials.pip_dark
        }
        position={[2.41, 0.747, 0.632]}
        rotation={[Math.PI / 4, Math.PI / 2, 0]}
        scale={0.098}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.power_pip_4.geometry}
        material={
          forceMultiplyer > 3 ? materials.pip_light : materials.pip_dark
        }
        position={[2.41, 0.747, 0.632]}
        rotation={[Math.PI / 4, Math.PI / 2, 0]}
        scale={0.098}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.life_crystal_1.geometry}
        material={playerHealth > 0 ? materials.pip_light : materials.pip_dark}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.life_crystal_2.geometry}
        material={playerHealth > 1 ? materials.pip_light : materials.pip_dark}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.life_crystal_3.geometry}
        material={playerHealth > 2 ? materials.pip_light : materials.pip_dark}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.life_crystal_4.geometry}
        material={playerHealth > 3 ? materials.pip_light : materials.pip_dark}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.life_crystal_5.geometry}
        material={playerHealth > 4 ? materials.pip_light : materials.pip_dark}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.score_target.geometry}
        material={materials.wood}
        position={[2.862, 1.254, 0.243]}
      >
        <Text3D
          font={'fonts/rubik_wet_paint_r.json'}
          scale={0.08}
          position={[-0.02, -0.03, 0]}
          material={materials.pip_dark}
        >
          {score}
        </Text3D>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Text.geometry}
        material={materials.pip_dark}
        position={[2.576, 1.237, 0.248]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.1}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.control_box.geometry}
        material={materials.wood}
      />
      <mesh
        castShadow
        receiveShadow
        onClick={(e) => {
          handleButtonClick(e);
        }}
        name='fire_btn'
        geometry={nodes.fire_btn.geometry}
        material={materials.red}
        position={[3.06, 0.69, 0.69]}
        rotation={[Math.PI / 4, 0, 0]}
        scale={0.205}
      />
      <group ref={props.cannonRef}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.kannon_base.geometry}
          material={materials.wood}
        />
        <a.mesh
          ref={props.cannonBodyRef}
          castShadow
          receiveShadow
          geometry={nodes.kannon_body.geometry}
          material={materials.metal}
          position={spring.position.to((x, y, z) => [x, y, z])}
        >
          {/* <mesh position={[0, 0.25, -8]} rotation={[Math.PI / 2, 0, 0]}>
            <coneGeometry args={[0.5, 15, 25]} />
            <meshBasicMaterial color='yellow' transparent opacity={0.2} />
          </mesh> */}
          <mesh
            castShadow
            receiveShadow
            ref={props.pumpkinSpawnPointRef}
            geometry={nodes.pumpkin_spawn_point.geometry}
            material={nodes.pumpkin_spawn_point.material}
            position={[0, 0, -1.315]}
            scale={0.272}
          />
        </a.mesh>
      </group>
    </group>
  );
}
