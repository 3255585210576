import { useEffect, useRef, useState } from 'react';
import {
  RigidBody,
  RapierRigidBody,
  CollisionEnterPayload,
  interactionGroups,
} from '@react-three/rapier';
import { useFrame } from '@react-three/fiber';
import { Vector3 } from 'three';
import { EnemyData, EnemyType } from './Graveyard';
import useGameStateStore, { GameState } from '../../stores/gameStateStore';
import useAudioStore, { Sounds } from '../../stores/audioStore';
import { getRandomInt } from '../../helpers/mathHelpers';

type EnemyProps = {
  onRemove: (name: string, wasDefeated: boolean) => void;
  data: EnemyData;
};

export default function Enemy({ onRemove, data }: EnemyProps) {
  const enemyRigidBodyRef = useRef<RapierRigidBody>(null);
  const { playerHealth, setPlayerHealth, gameState } = useGameStateStore();
  const { playSound } = useAudioStore();
  const [isAlive, setIsAlive] = useState(true);
  const xTarget = useRef<number>(0);
  const handleDeathState = () => {
    enemyRigidBodyRef.current?.setEnabledRotations(true, true, true, true);

    const numColliders = enemyRigidBodyRef.current?.numColliders() ?? 0;

    setTimeout(() => {
      if (enemyRigidBodyRef.current) {
        // set collision group to ignore projectiles
        for (let i = 0; i < numColliders; i++) {
          enemyRigidBodyRef.current
            ?.collider(i)
            .setCollisionGroups(interactionGroups([1], [0]));
        }
      }
    }, 50);
  };

  useEffect(() => {
    xTarget.current = getRandomInt(-2, 3);
    if (data.enemyType != EnemyType.ghost) return;
    if (gameState === GameState.inGame) {
      setTimeout(() => {
        enemyRigidBodyRef.current?.applyImpulse(new Vector3(0, 20, 0), true);
        enemyRigidBodyRef.current?.setGravityScale(0.25, true);
      }, 100);
    }
  }, []);

  const handleCollision = (e: CollisionEnterPayload) => {
    if (e.other.rigidBodyObject?.name?.startsWith('pumpkin') && isAlive) {
      setIsAlive(() => false);
      handleDeathState();
      setTimeout(() => {
        onRemove(data.name, true);
      }, 4000);
    }
  };

  // Move towards the origin every frame
  useFrame(() => {
    if (
      enemyRigidBodyRef.current &&
      isAlive &&
      gameState === GameState.inGame
    ) {
      const currentPos = enemyRigidBodyRef.current.translation();

      if (currentPos.z > -1.5) {
        setPlayerHealth(playerHealth - 1);
        playSound(Sounds.pop);
        onRemove(data.name, false);
      }

      const speedMultiplyer =
        data.enemyType == EnemyType.skeleton ? 1.5 * 100 : 1 * 100;
      // todo update direction so enemy heads towards xTarget on the x axis
      const direction = new Vector3(
        (xTarget.current - currentPos.x) * speedMultiplyer, // Adjust x to move toward xTarget
        data.enemyType === EnemyType.ghost ? 0 : -currentPos.y, // Ghosts float down
        -currentPos.z * speedMultiplyer // Move toward origin on z-axis
      );
      direction.normalize();
      const velocity = enemyRigidBodyRef.current.linvel();
      enemyRigidBodyRef.current.setLinvel(
        { x: direction.x, y: velocity.y * 0.5, z: direction.z },
        true
      );
    }
  });

  useEffect(() => {
    if (gameState !== GameState.inGame) {
      onRemove(data.name, false);
    }
  }, [gameState]);

  return (
    <group position={data.position} name={data.name}>
      <RigidBody
        ref={enemyRigidBodyRef}
        name={data.name}
        lockRotations={true}
        colliders={'cuboid'}
        gravityScale={1}
        density={5}
        friction={5}
        onCollisionEnter={handleCollision}
        collisionGroups={interactionGroups([0], [0])}
        ccd={true}
      >
        <primitive object={data.model} />
      </RigidBody>
    </group>
  );
}
