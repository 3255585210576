// Kannon.tsx
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Group, Mesh, Vector3 } from 'three';
import KannonModel from './components/KannonModel';
import useKannonStore from '../../stores/kannonStore';
import Projectile from './components/Projectile';
import useGameStateStore, { GameState } from '../../stores/gameStateStore';
import Tutorial from '../Tutorial/Tutorial';
import {
  KeyboardControls,
  KeyboardControlsEntry,
  useGLTF,
} from '@react-three/drei';

type ProjectileData = {
  name: string;
  id: number;
  position: [number, number, number];
  direction: Vector3;
  force: number;
};

export enum Controls {
  up = 'up',
  down = 'down',
  left = 'left',
  right = 'right',
  fire = 'fire',
  powerUp = 'powerUp',
  powerDown = 'powerDown',
}

export default function Kannon() {
  const map = useMemo<KeyboardControlsEntry<Controls>[]>(
    () => [
      { name: Controls.up, keys: ['ArrowUp'] },
      { name: Controls.down, keys: ['ArrowDown'] },
      { name: Controls.left, keys: ['ArrowLeft'] },
      { name: Controls.right, keys: ['ArrowRight'] },
      { name: Controls.fire, keys: ['Space'] },
      { name: Controls.powerUp, keys: ['KeyA'] },
      { name: Controls.powerDown, keys: ['KeyZ'] },
    ],
    []
  );
  const pumpkinSpawnPointRef = useRef<Mesh>(null);
  const cannonRef = useRef<Group>(null);
  const cannonBodyRef = useRef<Group>(null);
  const kart = useGLTF('models/kart.glb');
  const { gameState, setGameState } = useGameStateStore();

  const [projectiles, setProjectiles] = useState<ProjectileData[]>([]);
  const projectileCount = useRef(0);

  const { leftToRight, upDown, forceMultiplyer } = useKannonStore();

  // Variables for aiming
  const directionRef = useRef<Vector3>(new Vector3());
  const forceRef = useRef<number>(1);

  // Function to fire a projectile
  const fire = () => {
    const pos = new Vector3();
    pumpkinSpawnPointRef.current?.getWorldPosition(pos);

    projectileCount.current++;
    const projectileName = `pumpkin_${projectileCount.current}`;

    setProjectiles((prevProjectiles) => [
      ...prevProjectiles,
      {
        name: projectileName,
        id: projectileCount.current,
        position: [pos.x, pos.y, pos.z],
        direction: directionRef.current.clone(),
        force: forceRef.current,
      },
    ]);
  };

  // Function to remove a projectile
  const removeProjectile = useCallback((name: string) => {
    setProjectiles((prevProjectiles) =>
      prevProjectiles.filter((proj) => proj.name !== name)
    );
  }, []);

  // Update aiming direction and force when controls change
  useEffect(() => {
    // Map heading from range [-1, 1] to angle in radians from [-π/4, π/4]
    const heading = leftToRight;
    const angle = (heading * Math.PI) / 4; // -π/4 to π/4

    // Calculate horizontal direction
    const x = Math.sin(angle);
    const z = -Math.cos(angle);

    // Calculate vertical angle
    const verticalFactor = upDown || 0;
    const verticalAngle = (verticalFactor * Math.PI) / 4; // 0 to π/4
    const y = Math.sin(verticalAngle);

    // Update direction vector
    directionRef.current.set(x, y, z).normalize();

    // Update force based on force multiplier and vertical factor
    forceRef.current = forceMultiplyer;

    // Rotate the cannon
    if (cannonRef.current && cannonBodyRef.current) {
      cannonRef.current.rotation.y = -angle;
      cannonBodyRef.current.rotation.x = verticalAngle;
    }
  }, [leftToRight, upDown, forceMultiplyer]);

  return (
    <>
      {gameState == GameState.tutorial && <Tutorial />}
      <KeyboardControls map={map}>
        <KannonModel
          cannonBodyRef={cannonBodyRef}
          cannonRef={cannonRef}
          pumpkinSpawnPointRef={pumpkinSpawnPointRef}
          fire={fire}
        />
      </KeyboardControls>
      <primitive object={kart.scene} />
      {projectiles.map((proj) => (
        <Projectile
          key={proj.id}
          name={proj.name}
          position={proj.position}
          direction={proj.direction}
          force={proj.force}
          onRemove={removeProjectile}
        />
      ))}
    </>
  );
}
