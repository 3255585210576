import { useGLTF } from '@react-three/drei';
import { Material, Mesh, PointLight, Vector3 } from 'three';
import { useFrame } from '@react-three/fiber';
import { useRef, useState } from 'react';

interface Lanterns {
  nodes: {
    lightpost_1: Mesh;
    lightpost_2: Mesh;
    lightpost_3: Mesh;
    light_target_1: Mesh;
    light_target_2: Mesh;
    light_target_3: Mesh;
  };
  materials: {
    ['colormap.002']: Material;
  };
}

export default function Lanterns() {
  const { nodes, materials } = useGLTF(
    'models/lanterns.glb'
  ) as unknown as Lanterns;

  // References for the lights
  const light1 = useRef<PointLight>(null);
  const light2 = useRef<PointLight>(null);
  const light3 = useRef<PointLight>(null);

  // Target intensity states
  const [targetIntensity1, setTargetIntensity1] = useState(12);
  const [targetIntensity2, setTargetIntensity2] = useState(12);
  const [targetIntensity3, setTargetIntensity3] = useState(12);

  // Flickering effect
  useFrame((_, delta) => {
    // Function to set a new target intensity every second or so
    if (Math.random() < 0.1) {
      setTargetIntensity1(8 + Math.random() * 2);
      setTargetIntensity2(8 + Math.random() * 2);
      setTargetIntensity3(8 + Math.random() * 2);
    }

    if (light1.current)
      light1.current.intensity +=
        (targetIntensity1 - light1.current.intensity) * delta;
    if (light2.current)
      light2.current.intensity +=
        (targetIntensity2 - light2.current.intensity) * delta;
    if (light3.current)
      light3.current.intensity +=
        (targetIntensity3 - light3.current.intensity) * delta;
  });

  const lightPos = new Vector3(0, 0.9, 0.25);
  const lightColor = 0xbd6a26;
  return (
    <group>
      {/* Lightposts */}
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.lightpost_1.geometry}
        material={materials['colormap.002']}
        position={[4.235, 0, 0.939]}
        rotation={[0, -1.571, 0]}
        scale={2.032}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.lightpost_2.geometry}
        material={materials['colormap.002']}
        position={[4.235, 0, -7.955]}
        rotation={[0, -1.571, 0]}
        scale={2.032}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.lightpost_3.geometry}
        material={materials['colormap.002']}
        position={[4.235, 0, -18.052]}
        rotation={[0, -1.571, 0]}
        scale={2.032}
      />

      {/* Light Targets with Nested Point Lights */}
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.light_target_1.geometry}
        material={materials['colormap.002']}
        position={[4.235, 0, 0.939]}
        rotation={[0, -1.571, 0]}
        scale={2.032}
      >
        <pointLight
          castShadow
          shadow-mapSize-width={1}
          shadow-mapSize-height={1}
          position={lightPos}
          ref={light1}
          intensity={targetIntensity1}
          color={lightColor}
        />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.light_target_2.geometry}
        material={materials['colormap.002']}
        position={nodes.light_target_2.position}
        rotation={[0, -1.571, 0]}
        scale={2.032}
      >
        <pointLight
          position={lightPos}
          ref={light2}
          intensity={targetIntensity2}
          color={lightColor}
          castShadow
        />
      </mesh>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.light_target_3.geometry}
        material={materials['colormap.002']}
        position={nodes.light_target_3.position}
        rotation={[0, -1.571, 0]}
        scale={2.032}
      >
        <pointLight
          position={lightPos}
          ref={light3}
          intensity={targetIntensity3}
          color={lightColor}
          castShadow
        />
      </mesh>
    </group>
  );
}
