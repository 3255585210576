import { ThreeEvent } from '@react-three/fiber';
import { useState } from 'react';
import { useSpring, a } from '@react-spring/three'; // Import spring hooks from react-spring
import { Material, Mesh, Vector3 } from 'three';
import { Html, useGLTF } from '@react-three/drei';
import './Tutorial.css';
import useGameStateStore, { GameState } from '../../stores/gameStateStore';
import useAudioStore, { Music, Sounds } from '../../stores/audioStore';

const positions: { [key: number]: [number, number, number] } = {
  1: [0, 2, 0],
  2: [1.6, 1.5, 0.5],
  3: [2.4, 1.5, 0.5],
  4: [3.07, 1.5, 0.5],
  5: [2.0, 2, 0.5],
  6: [2.8, 2, 0.5],
};

type TutorialText = {
  mainText: string;
  keyboardHint?: string;
  subText: string;
};
const tutorialText: { [key: number]: TutorialText } = {
  1: {
    mainText: "This here is the cannon! Don't stand in front of it.",
    subText: 'Click the arrow to continue...',
  },
  2: {
    mainText: 'Use these to aim the cannon. Up, down, left, and right. ',
    keyboardHint: '(click on screen or use your keyboard arrow keys)',
    subText: 'Click the arrow to continue...',
  },
  3: {
    mainText: 'This controls the power of the cannon. More power, more boom! ',
    keyboardHint:
      '(click on screen or use A/Z keys on your keyboard for up/down)',
    subText: 'Click the arrow to continue...',
  },
  4: {
    mainText: 'Smash this button to release your volly unto the hordes! ',
    keyboardHint: '(click on screen or use the spacebar on your keyboard)',
    subText: 'Click the arrow to continue...',
  },
  5: {
    mainText:
      'These are your life crystals. Each time a ghoul makes it to the cannon, you lose one of these. If you lose them all, the beasts will prevail...',
    subText: 'Click the arrow to continue...',
  },
  6: {
    mainText:
      "This keeps track of how many ghouls you've smote! The higher the number, the greater the glory! ",
    subText: "If ye' be ready, click the arrow to begin!",
  },
};

interface Arrow {
  nodes: {
    arrow: Mesh;
  };
  materials: {
    pip_light: Material;
  };
}

export default function Tutorial() {
  const [tutorialState, setTutorialState] = useState<number>(1);
  const { setGameState } = useGameStateStore();
  const { playMusic: turnMusicOn, playSound } = useAudioStore();
  const { nodes, materials } = useGLTF('models/arrow.glb') as unknown as Arrow;
  const upDown = 0.1;

  // Set the spring animation with a slower duration and using arrays for position
  const props = useSpring({
    from: {
      position: [
        positions[tutorialState][0],
        positions[tutorialState][1] - upDown,
        positions[tutorialState][2],
      ],
    },
    to: async (next) => {
      while (true) {
        await next({
          position: [
            positions[tutorialState][0],
            positions[tutorialState][1] + upDown,
            positions[tutorialState][2],
          ],
          config: { duration: 500 }, // Slow down the bounce to 1 second
        });
        await next({
          position: [
            positions[tutorialState][0],
            positions[tutorialState][1] - upDown,
            positions[tutorialState][2],
          ],
          config: { duration: 500 }, // Slow down the bounce to 1 second
        });
      }
    },
    reset: true, // Restart the animation when tutorialState changes
  });

  const handleClick = (e: ThreeEvent<MouseEvent>) => {
    e.stopPropagation();
    playSound(Sounds.buttonClick);
    if (tutorialState >= 6) {
      // setTutorialState(() => 1);
      setGameState(GameState.inGame);
      turnMusicOn(Music.inGame);
      return;
    }
    setTutorialState((prev) => prev + 1);
  };

  return (
    <>
      <a.mesh
        position={props.position as unknown as Vector3}
        onClick={(e) => handleClick(e)}
        castShadow
        receiveShadow
        geometry={nodes.arrow.geometry}
        material={materials.pip_light}
        scale={[0.75, 0.75, 0.75]}
      ></a.mesh>

      <group position={positions[tutorialState]}>
        <Html position={[0, 1, 0]} center>
          <div className='tutorial-text'>
            <p className='main-text'>{tutorialText[tutorialState].mainText}</p>
            {tutorialText[tutorialState].keyboardHint && (
              <p className='keyboard-hint-text'>
                {tutorialText[tutorialState].keyboardHint}
              </p>
            )}
            <p className='sub-text'>{tutorialText[tutorialState].subText}</p>
          </div>
        </Html>
      </group>
    </>
  );
}
