import { Cloud, Clouds } from '@react-three/drei';
import { interactionGroups, RigidBody } from '@react-three/rapier';
import { MeshBasicMaterial } from 'three';

export default function Ground() {
  const cloudFade = 300;
  return (
    <>
      <Clouds material={MeshBasicMaterial} position={[0, 0, -15]}>
        <Cloud seed={2} scale={6} volume={6} color='grey' fade={cloudFade} />
        <Cloud
          seed={2}
          scale={10}
          volume={5}
          color='grey'
          opacity={0.5}
          fade={500}
          position={[0, 0, -60]}
        />
      </Clouds>

      <RigidBody
        type='fixed'
        collisionGroups={interactionGroups([0], [0, 1, 2])}
        name='ground'
      >
        <mesh receiveShadow position-y={-0.1}>
          <boxGeometry args={[1000, 0.2, 1000]} />
          <meshStandardMaterial color='greenyellow' />
        </mesh>
      </RigidBody>
    </>
  );
}
