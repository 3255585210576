import './modalContent.css';

export default function IntroContent() {
  return (
    <div className='modal-content'>
      <h2 className='modal-header'>Welcome Traveler!</h2>
      <div className='modal-body'>
        <p>
          Tis' a fortunate thing that you've found us this on this hallowed
          night! Tis' Halloween, and as is the yearly curse, all manner of
          ghouls, zombies, and ghosts have awoken from the town's graveyard to
          make their way into the village. We've set up the customary battery of
          pumpkin-powered cannon, but alas, on the way from town our cannoneer
          has fallen off his trusted steed and is unable to man the battery!
        </p>

        <p>
          Please, can you help to ward off the ghoulish horde?!? While no mere
          cannon can fend off the total bounty of hell, we can at least thin the
          herd. Worry not for the safety of the townsfolk for while the ghouls
          will give ol' pappy and the kids quite the fight, they seek not to
          murder but to only strike terror into the hearts of our simple
          townsfolk.
        </p>

        <p>
          Don't let this trick you into thinking this is but a fools errand!
          Manning the battery is a great honor and of the utmost importance!{' '}
        </p>

        <p>
          Pay attention, and I will describe the intricate workings of this
          awesome cannon...
        </p>
      </div>
    </div>
  );
}
