import './index.css';

import { Perf } from 'r3f-perf';
import { Stars, useProgress } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { Physics } from '@react-three/rapier';
import Kannon from './components/Kannon/Kannon';
import Ground from './components/Ground';
import Graveyard from './components/Graveyard/Graveyard';
import ContextModal from './components/2d/ContextModal/ContextModal';

import { useEffect, useState } from 'react';
import { Euler, Vector3 } from 'three';
import Landscape from './components/Landscape/Landscape';
import useIsMobile from './components/hooks/useIsMobile';
import Lanterns from './components/Lanterns/Lanterns';
import Options from './components/2d/Options/Options';
import useAudioStore, { Music } from './stores/audioStore';
import LandingPage from './components/2d/LandingPage/LandingPage';
import useGameStateStore, { GameState } from './stores/gameStateStore';
// const pumpkin = useGLTF('pumpkin-carved.glb');
const desktopCameraPosition = new Vector3(2.2, 2, 5);
const desktopCameraRotation: Euler = new Euler(0, 0.2, 0);
const mobileCameraPosition = new Vector3(1.5, 2, 4);
const mobileCameraRotation: Euler = new Euler(-0.15, 0.2, 0);
// Enable soft shadows globally

export default function App() {
  const isMobile = useIsMobile();
  const { gameState } = useGameStateStore();

  return (
    <>
      {gameState === GameState.landingPage && <LandingPage />}
      <Canvas
        shadows
        camera={{
          fov: 45,
          near: 0.1,
          far: 2000,
          position: isMobile ? mobileCameraPosition : desktopCameraPosition,
          rotation: isMobile ? mobileCameraRotation : desktopCameraRotation,
        }}
      >
        {/* <Perf position='bottom-right' /> */}
        <Stars />
        {/* <OrbitControls makeDefault /> */}

        <directionalLight
          castShadow
          position={[1, 2, 3]}
          intensity={1.5}
          color={'purple'}
        />
        <ambientLight intensity={1.5} color={'blue'} />
        <ambientLight intensity={0.7} color={'yellow'} />
        <Lanterns />
        <Physics>
          <Kannon />
          <Ground />
          <Graveyard />
          <Landscape />
        </Physics>
      </Canvas>
      <ContextModal />
      <Options />
    </>
  );
}
